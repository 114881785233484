<template>
    <div id="pagecontent" class="has-background-light">
        <div class="container">
            <div class="box">
                <b-tabs v-model="activeTab" size="is-medium">
                    <b-tab-item label="L'équipe">
                        <h2 class="soustitre1">Responsables du projet</h2>
                        <div class="columns py-5">
                            <div class="column is-4">
                                <figure class="image has-text-centered" style="margin-left: auto !important; margin-right: auto !important; width: 120px">
                                    <img class="is-rounded" src="../assets/auteurs/XB.png" alt="auteur">
                                </figure>
                            </div>
                            <div class="column is-8">
                                <b>Dr Xavier Benarous</b>
                                <br><br>Psychiatre d’Enfants et d’Adolescents
                                <ul>
                                    <li>Maitre de Conférences des Universités - Praticien Hospitalier. Service de Psychopathologie de l’Enfant et de l’Adolescent. Centre Hospitalier Universitaire Amiens-Picardie</li>
                                    <li>Membre du laboratoire INSERM U1105 Groupe de Recherche sur l’Analyse Multimodale de la Fonction Cérébrale (GRAMFC), Université Picardie Jules Verne</li>
                                    <li>Chercheur associé Groupe de Recherche Clinique n°15 – Troubles Psychiatriques et Développement (PSYDEV), Faculté de Médecine Sorbonne Université</li>
                                </ul>
                            </div>
                        </div>
                        <hr>
                        <div class="columns py-5">
                            <div class="column is-4 has-text-centered">
                                <img src="../assets/auteurs/OB.png" width="120" alt="auteur">
                            </div>
                            <div class="column is-8">
                                <b>Pr Olivier Bonnot</b>
                                <br><br>Psychiatre d’Enfants et d’Adolescents
                                <ul>
                                    <li>Professeur de Psychiatrie de l’Enfant et de l’ Adolescent - Université Paris-Saclay & Epsm Barthelemy Durand</li>
                                    <li>CESP-INSERM U1018. Équipe Moods </li>
                                </ul>
                            </div>
                        </div>
                        <hr>
                        <div class="columns py-5">
                            <div class="column is-4 has-text-centered">
                                <img src="../assets/auteurs/MS.png" width="120" alt="auteur">
                            </div>
                            <div class="column is-8">
                                <b>Pr Mario Speranza</b>
                                <br><br>Psychiatre d’Enfants et d’Adolescents
                                <ul>
                                    <li>Professeur des Universités – Praticien Hospitalier Chef du Service Universitaire de Psychiatrie de l'Enfant et de l'Adolescent. Centre Hospitalier de Versailles</li>
                                    <li>Responsable de l’équipe INSERM « Psychiatrie du développement et trajectoires », UMR 1018, Centre de Recherche en Épidémiologie et Santé des Populations », Université Paris-Saclay, UVSQ</li>
                                </ul>
                            </div>
                        </div>
                        <hr>
                        <div class="columns py-5">
                            <div class="column is-4 has-text-centered">
                                <img src="../assets/auteurs/NB.png" width="120" alt="auteur">
                            </div>
                            <div class="column is-8">
                                <b>Nicolas Bodeau</b>
                                <br><br>Biostatisticien, informaticien
                                <ul>
                                    <li>a longtemps travaillé en PEA à la Salpêtrière dans le cadre du Centre Maladies Rares à Expression Psychiatriques et Schizophrénies Précoces</li>
                                    <li>depuis 2015 dirigeant fondateur de Capitole Data, entreprise spécialisée dans le numérique (www.capitoledata.com) </li>
                                </ul>
                            </div>
                        </div>
                        <h2 class="soustitre1">Conseil scientifique du projet</h2>
                        <div class="table-container">
                            <table class="table-auto">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>Pr Florence ASKENAZY</p>
                                        </td>
                                        <td>
                                            <p>Trouble psychotique</p>
                                        </td>
                                        <td>
                                            <p>Professeur des Universit&eacute;s &ndash; Praticien Hospitalier. Service Universitaire de Psychiatrie de l'enfant et de l'adolescent. H&ocirc;pitaux p&eacute;diatriques de Nice - CHU Lenval</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Pr David COHEN</p>
                                        </td>
                                        <td>
                                            <p>D&eacute;pression</p>
                                            <p>Trouble psychotique</p>
                                        </td>
                                        <td>
                                            <p>Professeur des Universit&eacute;s &ndash; Praticien Hospitalier. Chef du Service de Psychiatrie de l&rsquo;Enfant et de l&rsquo;Adolescent, Groupe Hospitalier Piti&eacute; Salp&ecirc;tri&egrave;re, Sorbonne Universit&eacute;, Paris</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Pr Ang&egrave;le CONSOLI</p>
                                        </td>
                                        <td>
                                            <p>Trouble bipolaire</p>
                                        </td>
                                        <td>
                                            <p>Professeur des Universit&eacute;s &ndash; Praticien Hospitalier. Service de Psychiatrie de l&rsquo;Enfant et de l&rsquo;Adolescent, Groupe Hospitalier Piti&eacute; Salp&ecirc;tri&egrave;re, Sorbonne Universit&eacute;, Paris</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Pr Richard DELORME</p>
                                        </td>
                                        <td>
                                            <p>TOC</p>
                                        </td>
                                        <td>
                                            <p>Professeur des Universit&eacute;s &ndash; Praticien Hospitalier. Chef du Service de Psychiatrie de l&rsquo;Enfant et de l&rsquo;Adolescent, H&ocirc;pital Robert Debr&eacute;, Paris Centre D&rsquo;excellence InovAND</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Dr Vladimir FERRAFIAT</p>
                                        </td>
                                        <td>
                                            <p>Anxi&eacute;t&eacute;</p>
                                        </td>
                                        <td>
                                            <p>Praticien Hospitalier. Service de Psychiatrie de l&rsquo;Enfant et de l&rsquo;Adolescent, Centre Hospitalier Universitaire Charles Nicolle Rouen</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Pr Jean-Marc GUILE</p>
                                        </td>
                                        <td>
                                            <p>TDAH</p>
                                        </td>
                                        <td>
                                            <p>Professeur des Universit&eacute;s &ndash; Praticien Hospitalier. Chef du Service de Psychopathologie de l&rsquo;Enfant et de l&rsquo;Adolescent, Centre Hospitalier Amiens-Picardie</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Dr Barbara JAKUBOWICZ</p>
                                        </td>
                                        <td>
                                            <p>Anxi&eacute;t&eacute;</p>
                                        </td>
                                        <td>
                                            <p>Praticien Hospitalier. Fondation des &eacute;tudiants de France, Paris</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Dr Marie-Line MENARD</p>
                                        </td>
                                        <td>
                                            <p>Trouble psychotique</p>
                                        </td>
                                        <td>
                                            <p>Praticien Hospitalier. Service Universitaire de Psychiatrie de l'enfant et de l'adolescent. H&ocirc;pitaux p&eacute;diatriques de Nice - CHU Lenval</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Pr Diane PURPER OUAKIL</p>
                                        </td>
                                        <td>
                                            <p>TDAH</p>
                                        </td>
                                        <td>
                                            <p>Professeur des Universit&eacute;s &ndash; Praticien Hospitalier. Responsable du service de M&eacute;decine Psychologique de l&rsquo;Enfant et de l&rsquo;Adolescent (MPEA1) &ndash; H&ocirc;pital Saint Eloi, CHU de Montpellier.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Pr Carmen SCHRODER</p>
                                        </td>
                                        <td>
                                            <p>Sommeil</p>
                                        </td>
                                        <td>
                                            <p>Professeur des Universit&eacute;s &ndash; Praticien Hospitalier. Chef du Service de Psychiatrie de l&rsquo;Enfant et de l&rsquo;Adolescent, H&ocirc;pitaux Universitaires de Strasbourg - CNRS UPR 3212, INCI</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h2 class="soustitre1">Remerciements</h2>
                        <p>Dr Marie Besson (Hôpitaux Universitaire de Genève), Dr Hélène Lahaye (CHU Amiens-Picardie), Dr Caroline Tual (CHU Amiens-Picardie), Dr Vanessa Milhiet (Groupe Hospitalier Pitié-Salpêtrière), Dr Fanny Gollier-Briant (CHU de Nantes)</p>
                    </b-tab-item>
                    <b-tab-item label="Le projet">
                        <p class="soustitre1">Pr&eacute;sentation du projet&nbsp;&laquo;&nbsp;Site d&rsquo;aide &agrave; la prescription pharmacologique en psychiatrie de l&rsquo;enfant et de l&rsquo;adolescent&nbsp;&raquo;</p>
                        <p>La psychiatrie de l&rsquo;enfant et de l&rsquo;adolescent est une sp&eacute;cialit&eacute; m&eacute;dicale consacr&eacute;e &agrave; la prise en charge de l&rsquo;ensemble des troubles mentaux de l&rsquo;enfant et de l&rsquo;adolescent. Les troubles dont elle s&rsquo;occupe sont le r&eacute;sultat d&rsquo;une intrication complexe entre de multiples facteurs, g&eacute;n&eacute;tiques, constitutionnels, n&eacute;onataux, d&eacute;veloppementaux, environnementaux, traumatiques, accidentels, relationnels, sociaux voire culturels. Ind&eacute;pendamment de ses origines, la caract&eacute;ristique fondamentale de ces troubles est celle d&rsquo;entraver le d&eacute;veloppement de l&rsquo;enfant et de l&rsquo;adolescent sur plusieurs dimensions.</p>
                        <br>
                        <p>A l&rsquo;image de la multifactorialit&eacute; des troubles, les interventions en psychiatrie de l&rsquo;enfant et de l&rsquo;adolescent se doivent d&rsquo;&ecirc;tre multifocales, adapt&eacute;es au niveau du d&eacute;veloppement de l&rsquo;enfant et inclure syst&eacute;matiquement l&rsquo;entourage. Si les approches psychoth&eacute;rapiques (individuelles et familiales) et r&eacute;adaptatives (r&eacute;&eacute;ducations, ergoth&eacute;rapie, guidances &eacute;ducatives, actions sur les conditions de vie, insertion scolaire) sont souvent au premier plan de la strat&eacute;gie d&rsquo;intervention, le recours aux traitements pharmacologiques fait partie int&eacute;grante de l&rsquo;arsenal th&eacute;rapeutique du p&eacute;dopsychiatre, en particulier dans les situations les plus s&eacute;v&egrave;res. Les traitements pharmacologiques, &agrave; d&eacute;faut d&rsquo;&ecirc;tre curatifs, peuvent en effet participer &agrave; r&eacute;duire l&rsquo;intensit&eacute; des sympt&ocirc;mes et leurs impact sur le fonctionnement de l&rsquo;enfant, rendre l&rsquo;enfant plus accessible aux soins propos&eacute;s et participer &agrave; remobiliser son potentiel de d&eacute;veloppement.</p>
                        <br>
                        <p>Ces derni&egrave;res ann&eacute;es, la recherche m&eacute;dicale a mis &agrave; disposition des cliniciens toute une panoplie de mol&eacute;cules qui ont &eacute;largi la palette des outils d&rsquo;intervention du p&eacute;dopsychiatre. Mais il est difficile pour un clinicien de se tenir &agrave; jour de connaissances complexes et d&rsquo;orienter ces choix de mani&egrave;re pertinente d&rsquo;autant plus que la psychopharmacologie de l&rsquo;enfant pr&eacute;sente des particularit&eacute;s li&eacute;es l&rsquo;&acirc;ge, des sp&eacute;cificit&eacute;s biologiques et des modes de prescription particuliers.</p>
                        <br>
                        <p>Pour r&eacute;pondre &agrave; ce besoin d&rsquo;information actualis&eacute;e et d&rsquo;aide &agrave; la pratique, la Soci&eacute;t&eacute; Fran&ccedil;aise de Psychiatrie de l&rsquo;Enfant et de l&rsquo;Adolescent et des Disciplines Associ&eacute;es (SFPEADA), la soci&eacute;t&eacute; savante qui regroupe l&rsquo;ensemble des p&eacute;dopsychiatres et des professionnels qui travaillent dans le champ de la psychiatrie de l&rsquo;enfant et de l&rsquo;adolescent, a souhait&eacute; mettre &agrave; disposition de la communaut&eacute; des p&eacute;dopsychiatres un outil pragmatique d&rsquo;aide &agrave; la prescription pharmacologique bas&eacute; sur les connaissances scientifiques disponibles. Pour ce faire, la SFPEADA a d&eacute;velopp&eacute; un site internet d&rsquo;aide &agrave; la prescription pharmacologique accessible &agrave; tous les professionnels int&eacute;ress&eacute;s via le site de la soci&eacute;t&eacute;. Pour la r&eacute;daction des contenus du site nous nous sommes bas&eacute;s sur les recommandations nationales ou internationales publi&eacute;es par les soci&eacute;t&eacute;s savantes de la discipline et sur des meta-analyses et revues de synth&egrave;se de la litt&eacute;rature syst&eacute;matiques. Les contenus de chaque section ont &eacute;t&eacute; valid&eacute;s par un conseil scientifique d&rsquo;experts. Les informations contenues sur le site sont le reflet des connaissances actuelles et feront l&rsquo;objet de mises &agrave; jour r&eacute;guli&egrave;res par les responsables et le conseil scientifique du projet avec l&rsquo;inclusion progressive de nouvelles cat&eacute;gories de troubles et de situations cliniques.</p>
                        <br>
                        <p>Si le site contient des informations &agrave; caract&egrave;re m&eacute;dical, il a &eacute;t&eacute; volontairement dessin&eacute; pour &ecirc;tre accessible &agrave; tout professionnel ou personne int&eacute;ress&eacute;. En effet il est important que les connaissances valid&eacute;es sur les traitements pharmacologiques chez l&rsquo;enfant et l&rsquo;adolescent diffusent largement, la psycho&eacute;ducation &eacute;tant l&rsquo;une des composantes essentielles de l&rsquo;alliance th&eacute;rapeutique avec les familles et de l&rsquo;efficacit&eacute; de nos interventions.</p>
                        <p class="soustitre1">Mode d&rsquo;emploi du site </p>
                        <p>L&rsquo;ergonomie du site a &eacute;t&eacute; pens&eacute;e pour faciliter la navigation et l&rsquo;acc&egrave;s aux informations gr&acirc;ce &agrave; des visuels facilement identifiables. L&rsquo;entr&eacute;e dans le site se fait via les principales cat&eacute;gories diagnostiques des troubles mentaux de l&rsquo;enfant et de l&rsquo;adolescent. Pour chaque cat&eacute;gorie diagnostique, l&rsquo;information est organis&eacute;e par sections&nbsp;:</p>
                        <ul>
                            <li>
                                <strong>G&eacute;n&eacute;ralit&eacute;s</strong>&nbsp;: une prescription adapt&eacute;e d&eacute;bute par l&rsquo;identification de la cible clinique. Cette section pr&eacute;sente un bref rappel des principaux &eacute;l&eacute;ments s&eacute;miologiques du trouble, les crit&egrave;res pour poser le diagnostic, les &eacute;ventuels diagnostics diff&eacute;rentiels et les comorbidit&eacute;s&nbsp;;
                            </li>
                            <li>
                                <strong>Indications</strong>&nbsp;: cette section vise &agrave; pr&eacute;ciser les indications des diff&eacute;rentes classes m&eacute;dicamenteuses en fonction de l&rsquo;&acirc;ge et des &eacute;ventuelles comorbidit&eacute;s associ&eacute;es. Pour chaque mol&eacute;cule, le site pr&eacute;cise les indications d&rsquo;Autorisation de Mise sur le March&eacute; (AMM*) en France ou, en l&rsquo;absence d&rsquo;autorisation pour la France, les principales autorisations bas&eacute;es sur les recommandations internationales. Dans les deux cas, un tableau pr&eacute;sente la D&eacute;nomination Commune Internationale et la composition des mol&eacute;cules.
                            </li>
                            <li>
                                <strong>B&eacute;n&eacute;fices</strong>&nbsp;: la prescription m&eacute;dicamenteuse est bas&eacute;e sur un &eacute;quilibre entre b&eacute;n&eacute;fices et risques. Cette section pr&eacute;sente les donn&eacute;es sur l&rsquo;efficacit&eacute; du traitement (la taille d&rsquo;effet ou le nombre de sujets &agrave; traiter**) etles crit&egrave;res d&rsquo;am&eacute;lioration utilis&eacute;s dans la litt&eacute;rature pour identifier un changement significatif.
                            </li>
                            <li>
                                <strong>Effets ind&eacute;sirables&nbsp;: </strong>cette section pr&eacute;sente les principaux effets secondaires qui peuvent appara&icirc;tre lors de l&rsquo;utilisation d&rsquo;un traitement organis&eacute;s par tableaux selon le type de m&eacute;dicament et la fr&eacute;quence d&rsquo;apparition des effets secondaires.
                            </li>
                            <li>
                                <strong>Conseils avant la prescription&nbsp;:</strong> la mise en place d&rsquo;un traitement n&eacute;cessite plusieurs &eacute;tapes d&rsquo;analyse qui concernent la nature des troubles mais &eacute;galement l&rsquo;adh&eacute;sion du jeune et des parents. Pour chaque trouble, nous avons cr&eacute;&eacute; une fiche (check-list) qui permet au clinicien de se rappeler des &eacute;tapes indispensables &agrave; suivre avant l&rsquo;instauration d&rsquo;un traitement.
                            </li>
                            <li>
                                <strong>Instauration&nbsp;:</strong> cette section propose des crit&egrave;res pour choisir la mol&eacute;cule la plus adapt&eacute;e &agrave; la situation, les r&egrave;gles administratives de prescription, le bilan pr&eacute;-th&eacute;rapeutique, les modalit&eacute;s concr&egrave;tes d&rsquo;instauration de chaque traitement et les principales contre-indications.
                            </li>
                            <li>
                                <strong>Surveillance</strong>&nbsp;: l&rsquo;efficacit&eacute; d&rsquo;un traitement est fortement li&eacute;e&nbsp;&agrave; la qualit&eacute; du suivi en termes de compliance et de vigilance aux effets secondaires. Dans cette section, sont pr&eacute;sent&eacute;es les principales modalit&eacute;s de surveillance de l&rsquo;efficacit&eacute; comme des effets secondaires avec les outils qui peuvent &ecirc;tre employ&eacute;s et le calendrier de surveillance.
                            </li>
                            <li>
                                <strong>Conduite&nbsp;</strong>: comme toute pratique m&eacute;dicale, la prescription pharmacologique n&eacute;cessite d&rsquo;ajuster l&rsquo;action en fonction des observations sur l&rsquo;efficacit&eacute; et la tol&eacute;rance. Le site pr&eacute;sente dans cette section la conduite &agrave; tenir en fonction de la r&eacute;ponse clinique (am&eacute;lioration significative, am&eacute;lioration insuffisante, pas d&rsquo;am&eacute;lioration, am&eacute;lioration mais pr&eacute;sence d&rsquo;effets secondaires) avec diff&eacute;rents niveaux et alternatives th&eacute;rapeutiques (algorithmes d&eacute;cisionnels).
                            </li>
                            <li>
                                <strong>Ressources</strong>&nbsp;: dans cette section, le praticien pourra trouver pour chaque trouble des ressources &agrave; t&eacute;l&eacute;charger, notamment la check-list des &eacute;tapes d&rsquo;instauration du traitement, des fiches d&rsquo;information &agrave; transmettre aux patients, une fiche visuelle avec les algorithmes de prescription et un document p&eacute;dagogique sur la prescription selon les troubles. Dans cette section sont aussi list&eacute;es les principales recommandations internationales utilis&eacute;es pour la r&eacute;daction du site et des liens pour des sites d&rsquo;associations de patients vers lesquels orienter les familles.
                            </li>
                        </ul>
                        <br>
                        <p>*Pour &ecirc;tre commercialis&eacute;e, une sp&eacute;cialit&eacute; pharmaceutique doit obtenir pr&eacute;alablement une autorisation de mise sur le march&eacute; (AMM). L&rsquo;AMM est demand&eacute;e par un laboratoire pharmaceutique, pour sa sp&eacute;cialit&eacute;, sur la base d&rsquo;un dossier comportant des donn&eacute;es de qualit&eacute; pharmaceutique, d&rsquo;efficacit&eacute; et de s&eacute;curit&eacute;, dans l&rsquo;indication revendiqu&eacute;e.</p>
                        <p>**Le nombre de sujets &agrave; traiter (NST) est le r&eacute;sultat d'un calcul bas&eacute; sur des &eacute;tudes &eacute;pid&eacute;miologiques et destin&eacute; &agrave; appr&eacute;cier l'efficacit&eacute; d'une intervention de sant&eacute; publique, notamment m&eacute;dicamenteuse. Il correspond au nombre de patients &agrave; traiter pendant une p&eacute;riode donn&eacute;e pour &eacute;viter l'apparition d'un &eacute;v&egrave;nement d&eacute;favorable (par exemple, le nombre de patients qui doivent &ecirc;tre trait&eacute;s pour que l'un d'entre eux puisse en b&eacute;n&eacute;ficier par rapport &agrave; un t&eacute;moin dans un essai clinique).</p>
                    </b-tab-item>
                    <b-tab-item label="Cadre légal">
                        <p class="soustitre1">Rappel de la réglementation de la prescription chez l’enfant et l’adolescent</p>
                        <div class="columns py-5 mt-1">
                            <div class="column is-4 has-text-centered">
                                <img src="../assets/legal.png" width="120" alt="loi">
                            </div>
                            <div class="column is-8">
                                <p><br>L’article 8 du code de déontologie médicale (qui reprend l’article R4127-8 du Code de Santé Publique) précise que « le médecin est libre de ses prescriptions ». Cette liberté de prescription trouve sa limite dans l’obligation qui lui est faite de ne pas faire courir de risques aux patients.</p>
                            </div>
                        </div>
                        <div class="subtitle"><i>Contexte légal et rationnel scientifique de la prescription hors-AMM (Autorisation de Mise sur le Marché)</i></div>
                        De telles prescriptions hors AMM ne s’apprécient <b>qu’au cas par cas et doivent être adaptées à l’intérêt médical du patient. La prescription est justifiée par les données acquises de la science.</b> Le traitement doit être reconnu comme efficace et non dangereux par la communauté médicale au vu de la littérature scientifique. Le Résumé des Caractéristiques du Produits (RCP) d’un médicament sont consultables sur le site de l’ANSM. Son indication doit être considéré comme « indispensable » au regard de l’état du patient, de sa demande et des connaissances scientifiques du moment. Il ne doit <b>pas exister d’alternative médicamenteuse bénéficiant d’une AMM ou d’un accès précoce</b> (comme une Autorisation Temporaire d’Utilisation ou des Recommandations Temporaire d’Utilisation). Il faut être en mesure de justifier son choix de manière argumentée (études publiées, conférences de consensus avis d’expert).
                        <div class="subtitle"><i>Obligations du clinicien</i></div>
                        <ol>
                            <li>Il faut prendre le temps nécessaire pour <b>informer soigneusement le patient et de ses représentants légaux de ce choix.</b> Le prescripteur est tenu à une <b>obligation d’information renforcée</b> à l’égard de son patient et/ou de ses représentant légaux. Selon l’article L.5121-1, point III, du Code de Santé Publique en cas de prescription hors AMM, le prescripteur doit <b>« informer le patient que la prescription de la spécialité pharmaceutique n’est pas conforme à son autorisation de mise sur le marché, […] des risques encourus et des contraintes et des bénéfices susceptibles d’être apportés par le médicament et porte sur l’ordonnance la mention : « Prescription hors autorisation de mise sur le marché » […] et motiver sa prescription dans le dossier médical du patient »</b>.
                            </li>
                            <li>Il faut écarter tout produit contre-indiqué chez l’enfant et l’adolescent pour un motif de tolérance.
                            </li>
                            <li>Le consentement éclairé du patient et des parents doit être recueilli. L’information donnée doit être tracé dans le dossier médical. Si nécessaire le consentement peut être signé par le patient et/ou ses représentants légaux.
                            </li>
                            <li><b>La mention « hors AMM »</b> doit être clairement annotée sur l’ordonnance. En dehors de certains dispositifs dérogatoires (RTU) les médicaments prescrits hors AMM sont théoriquement non pris en charge par l’Assurance Maladie.</li>
                        </ol>
                        <br>
                        <p>Ce site présente une série de synthèses des données de la littérature (conférence de consensus, avis d’expert, recommandations françaises et internationales) regroupées par pathologies. Si l’utilisateur trouvera une synthèse de données récentes de la littérature scientifique pour éclairer sa prise de décisions, les recommandations faites par les auteurs (comme les indications, les algorithmes) sont présentées à titre informatif.</p>
                        <div>
                            <div class="subtitle"><i>Références</i></div>
                            <p> - L’article L.5121-1, point III, du Code de Santé Publique est disponible ici : <a href="https://www.legifrance.gouv.fr/codes/id/LEGIARTI000023755046/2222-02-22" target="_blank"> https://www.legifrance.gouv.fr/codes/id/LEGIARTI000023755046/2222-02-22</a>
                                <br> - Perraudin M, Coulon S, Willoquet G, Welniarz B. « La prescription hors autorisation de Mise sur le Marché (hors AMM) en pédopsychiatrie ». L’Information Psychiatrique</p>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeTab: 0,
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#pagecontent {
    min-height: 800px;
    padding-top: 100px;
}

.container {
    padding-bottom: 100px;
}

.subtitle {
    margin-top: 40px;
}

.title {
    padding-bottom: 30px;
}
</style>